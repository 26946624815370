import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { login } from '../api';
import Button from '../components/Button/Button';
import Logo from '../components/Logo/Logo';
import PageLayout from '../components/Layout/PageLayout';
import Spinner from '../components/Spinner/Spinner';
import ErrorText from '../components/ErrorText/ErrorText';
import './login-page.scss';

export default function LoginPage({ onLogin }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginState, setLoginState] = useState(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onLoginClick = async (e) => {
    e.preventDefault();

    setLoginState('logging-in');

    try {
      const { token } = (await login({ email, password })).data;
      localStorage.setItem('token', token);

      queryClient.clear();

      onLogin();
      navigate('/app/create');
    } catch {
      setLoginState('error');
    }
  };

  const loggingIn = loginState === 'logging-in';

  return (
    <PageLayout includeMenu={false}>
      <div className="login-page">
        <form>
          <h1>Login</h1>
          <input
            disabled={loggingIn}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            disabled={loggingIn}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {loginState === 'error' && (
            <ErrorText center>
              We couldn&apos;t sign you in. Please make sure you&apos;ve entered your credentials correctly.
            </ErrorText>
          )}
          {loggingIn ? <Spinner /> : <Button onClick={onLoginClick}>Sign in</Button>}

          <div className="password-retrieve-link">
            <p>
              <Link to="/password-retrieve">Forgot password?</Link>
            </p>
          </div>

          <div className="register-text">
            <p>Don&apos;t have an account?</p>
            <Link className="register-link" to="/register">
              Register here
            </Link>
          </div>
        </form>
        <div className="hero">
          <Logo />
          <h1 className="text-white">
            Let the technology work for <em className="colorize">you</em>
          </h1>
        </div>
      </div>
    </PageLayout>
  );
}
