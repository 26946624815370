import axios from 'axios';
import { toast } from 'react-toastify';
import { uriToStream } from './util';
import meetingTypes from './components/CreateMeetingForm/meetingTypes';

const apiRoot = process.env.REACT_APP_API_ROOT ?? 'https://qa-api.phasedconnect.eu/api/v1';

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return config;
  }

  // eslint-disable-next-line no-param-reassign
  config.headers = config.headers ?? {};

  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = token;

  return config;
});

function apiUrl(url) {
  return `${apiRoot}/${url}`;
}

export async function getMeetings() {
  return axios.get(apiUrl('meetings'));
}

export function getMeeting(id) {
  return axios.get(apiUrl(`meetings/${id}`));
}

export function deleteMeeting(id) {
  return axios.delete(apiUrl(`meetings/${id}`));
}

export function stopMeeting(id) {
  return axios.post(apiUrl(`meetings/${id}/stop`));
}

export function createMeetings({ primaryStream, startTime, durationMinutes, platforms = [] }) {
  const primaryStreamKey = uriToStream(primaryStream);
  if (!primaryStreamKey) {
    throw new Error('Could not determine source meeting type');
  }

  const streamServices = {};

  for (const platform of Object.keys(meetingTypes)) {
    streamServices[platform] = {
      ignore: ![...platforms, primaryStreamKey].includes(platform),
    };
  }

  streamServices[primaryStreamKey].uri = primaryStream;
  const data = {
    streamServices,
    startTime,
    durationMinutes,
  };

  return axios.post(apiUrl('meetings'), data);
}

export function login(loginData) {
  return axios.post(apiUrl('auth/login/jwt'), loginData);
}

export function logout() {
  return axios.post(apiUrl('auth/logout'));
}

export async function fetchCurrentUser() {
  const response = await axios.get(apiUrl('auth/current-user'));
  return response.data;
}

export function register(registerData) {
  return axios.post(apiUrl('auth/register'), registerData);
}

export function changePassword(newPassword, confirmPassword) {
  const token = localStorage.getItem('token');
  return axios.put(
    apiUrl('auth/change-password/jwt'),
    {
      newPassword,
      confirmPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}

export const fetchUsers = async () => {
  try {
    const response = await axios.get(apiUrl('users'));
    return response.data;
  } catch (error) {
    toast.error('Error fetching users:', error.response.data);
    throw error;
  }
};

export async function addUser(newUser) {
  return axios.post(apiUrl('users'), newUser);
}

export async function deleteUser(userId) {
  return axios.delete(apiUrl(`users/${userId}`));
}

export async function updateUserPassword(userId, newPassword) {
  return axios.put(apiUrl(`users/${userId}/password`), {
    newPassword,
    confirmPassword: newPassword,
  });
}

export async function disableUser(userId) {
  return axios.put(apiUrl(`users/${userId}/disable`));
}

export async function retrievePassword(currentEmail) {
  return axios.post(apiUrl('auth/retrieve-password'), { email: currentEmail });
}

export async function setupPassword(newPassword, confirmPassword, resetToken) {
  return axios.post(apiUrl('auth/setup-password'), {
    newPassword,
    confirmPassword,
    resetToken,
  });
}
