/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';

import clsx from 'clsx';

import meetingTypes from '../CreateMeetingForm/meetingTypes';

import { uriToStream } from '../../util';

import './platform-selector.scss';

export default function PlatformSelector({ register, formErrors, selectedPlatforms, onPlatformToggle, watch }) {
  const primaryStreamKey = uriToStream(watch('primaryStream'));

  useEffect(() => {
    if (!primaryStreamKey) {
      return;
    }
    if (!selectedPlatforms.includes(primaryStreamKey)) {
      onPlatformToggle(primaryStreamKey);
    }
  }, [primaryStreamKey]);

  const primaryStreamData = meetingTypes[primaryStreamKey];

  return (
    <div className="platform-selector">
      <div className="input-container">
        <label className="text-base" htmlFor="meeting-link">
          Existing URL
        </label>
        <div className="input-wrapper">
          <input {...register('primaryStream', { validate: (v) => uriToStream(v) !== null })} type="text" />
          {primaryStreamData ? <img src={primaryStreamData.icon} alt={primaryStreamData.name} /> : <div />}
          {formErrors.primaryStream && <div className="form-error">Invalid link</div>}
        </div>
      </div>
      <div />

      <div>
        <h3 className="text-base">Select platforms</h3>
        <div className="platforms">
          {Object.entries(meetingTypes).map(([k, m]) => (
            <button
              className={clsx(
                'stream-button',
                primaryStreamKey === k && 'enabled primary',
                selectedPlatforms.includes(k) ? 'enabled' : 'disabled'
              )}
              key={m.name}
              type="button"
              onClick={() => k !== primaryStreamKey && onPlatformToggle(k)}
            >
              <img src={m.icon} alt={m.name} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
