import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/Button/Button';
import Logo from '../components/Logo/Logo';
import PageLayout from '../components/Layout/PageLayout';
import ErrorText from '../components/ErrorText/ErrorText';
import './password-retrieve.scss';
import { retrievePassword } from '../api';
import Spinner from '../components/Spinner/Spinner';
import SuccessText from '../components/SuccessText/SuccessText';

export default function PasswordRetrievePage() {
  const [email, setEmail] = useState('');
  const [retrieveState, setRetrieveState] = useState(null);

  const onSubmitClick = async (e) => {
    e.preventDefault();

    setRetrieveState('sending');
    try {
      await retrievePassword(email);
      setRetrieveState('done');
    } catch (err) {
      const res = JSON.parse(err.request.response);
      const message = res.message[0];
      setRetrieveState(message);
    }
  };

  const sending = retrieveState === 'sending';
  const done = retrieveState === 'done';
  const error = !sending && !done && retrieveState !== null;

  return (
    <PageLayout includeMenu={false}>
      <div className="passwordretrieval-page">
        <form>
          <h1>Retrieve password</h1>
          <input
            disabled={done}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error && <ErrorText center>{retrieveState}</ErrorText>}
          {done && <SuccessText center>Password reset link will be sent if this email is registered.</SuccessText>}
          {sending ? (
            <Spinner />
          ) : (
            <Button disabled={done} onClick={onSubmitClick}>
              Submit
            </Button>
          )}

          <div className="login-link">
            <p>
              <Link to="/">Back to Login</Link>
            </p>
          </div>
        </form>

        <div className="hero">
          <Logo />
          <h1 className="text-white">
            Let the technology work for <em className="colorize">you</em>
          </h1>
        </div>
      </div>
    </PageLayout>
  );
}
