import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Button from '../components/Button/Button';
import Logo from '../components/Logo/Logo';
import PageLayout from '../components/Layout/PageLayout';
import ErrorText from '../components/ErrorText/ErrorText';
import './password-setup.scss';
import Spinner from '../components/Spinner/Spinner';
import SuccessText from '../components/SuccessText/SuccessText';
import { setupPassword } from '../api';

export default function PasswordSetupPage() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [retrieveState, setRetrieveState] = useState(null);
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get('token');

  const onSubmitClick = async (e) => {
    e.preventDefault();

    setRetrieveState('sending');
    try {
      await setupPassword(newPassword, confirmPassword, resetToken);
      setRetrieveState('done');
    } catch (err) {
      const res = JSON.parse(err.request.response);
      const { message } = res;
      setRetrieveState(message);
    }
  };

  const sending = retrieveState === 'sending';
  const done = retrieveState === 'done';
  const error = !sending && !done && retrieveState !== null;

  return (
    <PageLayout includeMenu={false}>
      <div className="password-setup-page">
        <form>
          <h1>Setup a new password</h1>
          <input
            disabled={done}
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <input
            disabled={done}
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && <ErrorText center>{retrieveState}</ErrorText>}
          {done && <SuccessText center>Password retrieved successfully.</SuccessText>}
          {sending ? (
            <Spinner />
          ) : (
            <Button disabled={done} onClick={onSubmitClick}>
              Submit
            </Button>
          )}

          <div className="login-link">
            <p>
              <Link to="/">Back to Login</Link>
            </p>
          </div>
        </form>

        <div className="hero">
          <Logo />
          <h1 className="text-white">
            Let the technology work for <em className="colorize">you</em>
          </h1>
        </div>
      </div>
    </PageLayout>
  );
}
