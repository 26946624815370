import React from 'react';

import { useQuery } from '@tanstack/react-query';

import { getMeetings } from '../api';
import { sortMeetings } from '../util';

import PageLayout from '../components/Layout/PageLayout';
import Spinner from '../components/Spinner/Spinner';

import MeetingList from '../components/MeetingList/MeetingList';

export default function HistoryPage() {
  const { isLoading, data } = useQuery({
    queryKey: ['overview'],
    queryFn: getMeetings,
    cacheTime: 0,
    staleTime: 0,
  });

  const activeMeetings = sortMeetings(data?.data)?.filter((m) => m.status === 'errored' || m.status === 'finished');

  return (
    <PageLayout pageClassName="overview-page" title="Meeting history">
      {isLoading ? <Spinner /> : <MeetingList meetings={activeMeetings} />}
    </PageLayout>
  );
}
